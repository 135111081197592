<template>
  <div class="">
      <a-layout>
        <a-layout-sider v-model="collapsed" :trigger="null" collapsible
          style="padding-top:20px;margin-left:10px;width:200px;background:#fff"
        >
          <kys-sidebar ></kys-sidebar>
        </a-layout-sider>
        <a-layout>
          <a-layout-content
            :style="{ padding: '24px', background: '#fff', minHeight: '280px' }"
          >
          <h2 class="kys-h1" :style="{ color: queue.isDown ? 'red' : 'green', 'font-size': '26px' }">Queue Health             
            <a-icon v-if="queue.isDown" type="warning" theme="filled" :style="{ color: 'red', 'font-size': '26px' }"/>
          </h2>
          <a-statistic title="Approximate Depth" :value="queue.count" style="margin-right: 50px" />
          <a-row type="flex" :gutter="60">
            <a-col>
              <h2 class="kys-h1" :style="{ color: queue.isDown ? 'red' : 'green', 'font-size': '26px' }">Namelist</h2>
              <a-card class="kys-card" :bodyStyle="{}" 
                      v-for="namelist in namelist_stats" 
                      :key="namelist.id" 
                      v-show="namelist.stats.new > 0 "            
              >
                <h1>{{ namelist.id }}.{{ namelist.name }}
                  
                  <span v-if="namelist.batch_id"> / {{ namelist.batch_id }}</span></h1>
                <a-progress v-if="namelist.stats.new > 0 "
                            :stroke-color="{
                              '0%': namelist.stats.processing > 0 ? '#108ee9': 'blue',
                              '100%':  namelist.stats.processing > 0 ? '#87d068': 'blue',
                            }"
                            :percent="getPercent(namelist.stats)"
                          />
                <a-row type="flex" :gutter="10">

                  <a-col>
                      <a-statistic title="Entries" :value-style="{ 'font-size': '1.2em', color: 'blue' }" :value="namelist.stats.num_entries" style="margin-right: 50px" />
                    </a-col>
                    <a-col>
                      <a-statistic title="New" :value-style="{ 'font-size': '1.2em', color: 'blue' }" :value="namelist.stats.new" style="margin-right: 50px" />
                    </a-col>
                    <a-col>
                      <a-statistic title="Stale" :value-style="{ 'font-size': '1.2em', color: 'blue' }" :value="namelist.stats.stale" style="margin-right: 50px" />
                    </a-col>
                    <a-col>
                      <a-statistic title="Matched" :value-style="{ 'font-size': '1.2em', color: '#3f8600' }" :value="namelist.stats.linkedin_found" style="margin-right: 50px" />
                    </a-col>
                    <a-col>
                      <a-statistic title="No Match" :value-style="{ 'font-size': '1.2em', color: 'red' }" :value="namelist.stats.no_match" style="margin-right: 50px" />
                    </a-col>
                  </a-row>
              </a-card>            
            </a-col>
            <a-col>
              <h2 class="kys-h1" :style="{ color: queue.isDown ? 'red' : 'green', 'font-size': '26px' }">History</h2>
              <a-list size="small" item-layout="horizontal"  :data-source="history">
                <a-list-item slot="renderItem" slot-scope="item, index">
                  <a slot="actions">
                    <a-tag :color="getStatusColor(item[1])">{{ item[1] }}</a-tag>
                  </a>                 
                 <a-list-item-meta
                    :description="fromNow(item[3])"
                  >
                  <div slot="title">{{ item[0] }} <a-icon v-if="item[1] == 'LINKEDIN_FOUND'" slot="dot" theme="filled" type="linkedin" :style="{ color: '#0090ff', 'font-size': '16px' }" />
</div>
                </a-list-item-meta>
                </a-list-item>
                <div slot="footer">
                </div>
              </a-list>              
            </a-col>
          </a-row>
          </a-layout-content>
        </a-layout>
      </a-layout>
  </div>
</template>
<style>

  .kys-h1 {
    padding-top: 20px;
  }  

  .kys-card {
    margin-bottom: 15px;
    max-width: 1080px;
  }

  .kys-card .ant-card-body {
    padding: 10px 20px;
  }

</style>
<script>

import KysSidebar from "../../components/HomeSidebar";
import moment from 'moment'

export default {
  name: 'InternalLinkedin',
  components: {
    KysSidebar
  },
  data() {
    return {
      collapsed: false,
      last_check: new Date().getTime(),
      queue: {
        isDown: false,
        count: 0
      },
      namelist_stats: [],
      history:[]
    }
  },
  mounted: async function () {
    this.getQueueDepth()
    this.getNamelistStats()
    this.getHistory()

    this.interval = setInterval( function () {
      if(this.queue.count === 0 && new Date().getTime() - this.last_check < 5 * 50000 ){
        console.log('sleeping additional time')
        return
      }
      console.log(new Date())
      this.last_check = new Date().getTime()
      this.getQueueDepth()
      this.getNamelistStats()
      this.getHistory()
    }.bind(this), 15000); 

  },
  computed: {

  },
  methods: {
    getPercent(s){
      let pct = 100 - (s.stale + s.new) / ( s.stale + s.new + s.linkedin_found + s.no_match ) * 100
      return parseInt(pct)
    },
    fromNow (t) {
      return moment(t).fromNow()
    },
    getStatusColor (s) {
      return s == 'ERROR' ? '#f50' : s == 'LINKEDIN_FOUND' ? 'blue' : ''
    },
    getQueueDepth: async function () {
      var response = await this.$kysapi.get('api/linkedin/queue' , this.$auth)
      // console.log(response)
      this.queue.count = response.data
    },
    getNamelistStats: async function () {
      var response = await this.$kysapi.get('api/linkedin/nameliststats' , this.$auth)
      // console.log(response)
      this.namelist_stats = response.data
      this.namelist_stats.sort((a,b) => b.stats.new - a.stats.new)
    },
    getHistory: async function () {
      var response = await this.$kysapi.get('api/linkedin/history' , this.$auth)
      // console.log(response)
      this.history = response.data
      this.queue.isDown =  this.history[0][1] == 'ERROR'
    },

  },
  beforeDestroy: function(){
    clearInterval(this.interval);
  }
}
</script>
