<template>
  <div>
    <a-row>
       <a-col :span="17" style="padding-right:20px;">
         <div style="font-size:20px;color:black">
             {{ subscriber ? subscriber.name : '' }}
             <span v-if="subscriberBookmarked"><a-icon type="star" theme="twoTone" two-tone-color="#c7c219"/></span>

         </div>
         <div style="font-size:12px;color:grey">

           {{ subscriber.reference }}
         </div>
         <a-row style="padding-top:20px;">
            <a-col :span="12">
              <section >
                  <a-timeline>
                    <a-timeline-item v-if="subscriber.email_address">
                      <a-icon slot="dot" type="mail" style="font-size: 16px;" />
                      {{ subscriber.email_address }}
                    </a-timeline-item>
                    <a-timeline-item>
                      <a-icon slot="dot" type="bank" style="font-size: 16px;" />

                      <span v-if="!subscriber.employer_name">No Employer</span>
                      <span><a @click="configureKysFirm(subscriber.employer_name)">{{subscriber.employer_name }}</a></span>
                      <kys-firm-info :name="subscriber.employer_name" :kysfirms="kysfirms"/>
                      <div  class="employment_title" v-for="employment_title in subscriber.info.employment_titles">
                      {{ employment_title }}
                    </div>
                    </a-timeline-item>
                    <a-timeline-item v-if="subscriber.employer_address">
                      <a-icon slot="dot" type="environment" style="font-size: 16px;" />
                      <div class="address" >{{ subscriber.employer_address }}</div>
                    </a-timeline-item>                    
                    <a-timeline-item v-if="subscriber.info.attributes && ( subscriber.info.attributes.crd || subscriber.info.attributes.sec )">
                      <a-icon slot="dot" type="info-circle" style="font-size: 16px;" />
                      <div class="address" v-if="subscriber.info.attributes.crd">CRD: {{ subscriber.info.attributes.crd }}</div>
                      <div class="address" v-if="subscriber.info.attributes.sec">SEC: {{ subscriber.info.attributes.sec }}</div>
                    </a-timeline-item>
                    <a-timeline-item v-if="subscriber.country">
                      <a-icon slot="dot" type="environment" style="font-size: 16px;" />
                      <div class="address" >{{ subscriber.address }}</div>
                      <div class="address" >{{ subscriber.country }}</div>
                    </a-timeline-item>
                  </a-timeline>
              </section>
             </a-col>
            <a-col :span="12">
              <LinkedIn :profile="subscriber.linkedin_profile" :kysfirms="kysfirms" v-on:kysfirm="configureKysFirm"/>
            </a-col>
        </a-row>
         <a-tabs :default-active-key="defaultTab">
           <a-tab-pane key="All">
             <span slot="tab">
                <a-icon type="appstore" />
              </span>
              <section>
                 <div style="border:1px solid #ddd;margin-bottom:10px;" id="basic_card" v-for="result in confirmedRegisterResults">
                    <RegisterResultCard :result="result"/>
               </div>
                <div style="border:1px solid #ddd;margin-bottom:10px;" id="basic_card" v-for="result in probableRegisterResults">
                   <RegisterResultCard :result="result"/>
                </div>
                <section>
                   <div style="border:1px solid #ddd;margin-bottom:10px;" id="basic_card" v-for="register in subscriberEmployerRegisterResults">
                     <EmployerResultCard :result="register"/>
                   </div>
                </section>
                <div style="border:1px solid #ddd;margin-bottom:10px;" id="basic_card" v-for="result in employerMatches">
                   <EmployerResultCard :result="result"/>
                </div>
                <div style="border:1px solid #ddd;margin-bottom:10px;" v-if="subscriber.info.domain.data">
                  <div
                    class="e-card"
                    style="display:flex;flex-direction:row">
                    <div style="flex:1">
                      <div class="e-card-header">
                        <a-avatar style="backgroundColor:#6777ec" size="large" icon="bank">
                        </a-avatar>
                      </div>
                    </div>
                    <div style="flex:10">
                         <div class="e-card-header">
                             <div class="e-card-header-caption">
                                <div>{{ subscriber.info.domain.data.company_name }}</div>
                                <div>{{ subscriber.info.domain.url }}</div>
                                 <a target="_blank" :href="subscriber.info.domain.data.link">{{ subscriber.info.domain.data.link }}</a>
                             </div>
                         </div>

                        <div class="e-card-actions">

                        </div>
                  </div>
                  </div>
                </div>
            </section>
          </a-tab-pane>
           <a-tab-pane v-show="confirmedRegisterResults.length > 0" key="Matched Results" tab="Matched Results">
           <section>
              <div style="border:1px solid #ddd;margin-bottom:10px;" id="basic_card" v-for="result in confirmedRegisterResults">
                 <RegisterResultCard :result="result"/>
            </div>
           </section>
           </a-tab-pane>
           <a-tab-pane key="Probables" v-show="probableRegisterResults.length > 0" tab="Probables">
           <section>
                <div style="border:1px solid #ddd;margin-bottom:10px;" id="basic_card" v-for="result in probableRegisterResults">
                   <RegisterResultCard :result="result"/>
              </div>
           </section>
           <section>
              <div style="border:1px solid #ddd;margin-bottom:10px;" id="basic_card" v-for="register in subscriberEmployerRegisterResults">
                     <div class="e-card" style="display:flex;flex-direction:row">
                       <div v style="flex:1">
                         <div class="e-card-header">
                           <a-statistic :title="register.source" :value="register.name_score"/>
                         </div>
                       </div>
                       <div style="flex:4">
                            <div class="e-card-header">
                                <div class="e-card-header-caption">
                                    <div class="e-card-header-title">
                                      {{ register.name }}
                                    </div>
                                    <div class="e-card-sub-title">{{ register.source }} | {{ register.country }} | {{ register.reference_id }} </div>
                                    <div class="e-card-sub-title">{{ register.status }}</div>
                                </div>
                            </div>
                     </div>
                 </div>
            </div>
         </section>

           <section>
              <div style="border:1px solid #ddd;margin-bottom:10px;" id="basic_card" v-for="sandp in subscriberSANDPResults">
                     <div class="e-card" style="display:flex;flex-direction:row">
                       <div v style="flex:1">
                         <div class="e-card-header">
                           <a-statistic title="S&P" :value="sandp.score"/>
                         </div>
                       </div>
                       <div style="flex:4">
                            <div class="e-card-header">
                                <div class="e-card-header-caption">
                                    <div class="e-card-header-title">{{ sandp.name }}</div>
                                    <div class="e-card-sub-title">S&P | {{ sandp.parent_id }} <span v-if="sandp.id != sandp.parent_id"> | {{ sandp.id }}</span></div>
                                    <div class="e-card-sub-title">{{ sandp.industry }}</div>
                                </div>
                            </div>
                     </div>
                 </div>
            </div>
         </section>

           </a-tab-pane>
           <a-tab-pane key="3" tab="KYS Firms">
             <section>
                <div style="border:1px solid #ddd;margin-bottom:10px;" id="basic_card" v-for="register in subscriberEmployerRegisterResults">
                       <div class="e-card" style="display:flex;flex-direction:row">
                         <div v style="flex:1">
                           <div class="e-card-header">
                             <a-statistic :title="register.source" :value="register.name_score"/>
                           </div>
                         </div>
                         <div style="flex:4">
                              <div class="e-card-header">
                                  <div class="e-card-header-caption">
                                      <div class="e-card-header-title">
                                        {{ register.name }}
                                      </div>
                                      <div class="e-card-sub-title">{{ register.source }} | {{ register.country }} | {{ register.reference_id }} </div>
                                      <div class="e-card-sub-title">{{ register.status }}</div>
                                  </div>
                              </div>
                       </div>
                   </div>
              </div>
             </section>
             <a-table v-if="subscriber.info && subscriber.info.employers" size="small"
              :columns="employerTable.columns"
              :data-source="subscriber.info.employers">
                <span slot="names" slot-scope="names">
                  <div v-for="(name,idx) in names" :key="'name_'+idx">{{name}}</div>
                </span>
                <span slot="register" slot-scope="register">
                  <!-- <span v-if="register.length > 0">{{ register.length }}</span> -->
                </span>
                <span slot="sandp" slot-scope="sandp">
                  {{ subscriberSANDPResults.length }}
                  <!-- <span v-if="sandp.length > 0">{{ sandp.length }}</span> -->
                </span>

              </a-table>
            </a-tab-pane>
         </a-tabs>
       </a-col>
       <a-col :span="7" style="padding-top:15px;">
         <section  style="padding-bottom:10px">
         <h4>Internal
           <a-dropdown v-if="$auth.user.profile.role == 'admin'" >
               <a class="ant-dropdown-link" @click="e => e.preventDefault()">
                   <a-icon type="down-square" />                  </a>
               <a-menu slot="overlay">
                 <a-menu-item>
                   <a href="javascript:;">share </a>
                 </a-menu-item>
                 <a-menu-item>
                   <a href="javascript:;" @click="bookmarkSubscriber">bookmark</a>
                 </a-menu-item>
                 <a-menu-item>
                   <a href="javascript:;" @click="refreshSubscriber">refresh </a>
                 </a-menu-item>
                 <a-menu-divider />
                 <a-menu-item>
                   <a href="javascript:;" @click="json= true;">JSON</a>
                 </a-menu-item>
               </a-menu>
             </a-dropdown>
         </h4>
         <div style="font-size:12px">N{{ subscriber.namelist_id }}S{{ subscriber.id }}
           <a :href="'/nsid/N' + subscriber.namelist_id + 'S' + subscriber.id" target="_blank">
             <a-icon type="link"/>
           </a>
         </div>
         <div style="font-size:12px">{{ subscriber.reference }}</div>
       </section>
       <section v-if="subscriber.linkedin" style="padding-bottom:10px">
         <h4>Social</h4>
         <a-icon  theme="filled" type="linkedin" style="font-size: 16px; padding-right:5px" />
         <span style="font-size:12px">{{ subscriber.linkedin.replace("https://www.linkedin.com/in/",'') }}</span>
         <a :href="subscriber.linkedin" target="_blank"><a-icon type="link"/></a>

       </section>

         <section>
           <h4>Usecases
             <!-- <a-dropdown>
                 <a class="ant-dropdown-link" @click="e => e.preventDefault()">
                   <a-icon type="down-square" />
                 </a>
                 <a-menu slot="overlay">
                   <a-menu-item>
                     <a target="_blank" @click="requeueSubscriberUsecases" >reload</a>
                   </a-menu-item>
                 </a-menu>
               </a-dropdown> -->
             </h4>
               <div v-if="subscriber.info && subscriber.info.usecase_results">
                  <a-card size="small"  style="margin-bottom:10px;" v-for="uc in subscriber.info.usecase_results">
                    <a-card-meta style="padding-bottom:5px;">
                      <div slot="title" style="font-size:.8em">    <a-badge status="success" />{{ uc.result }}</div>
                    </a-card-meta>
                      <div style="font-size:.8em"><pre>{{ uc.individual_note }}</pre></div>
                      <div style="font-size:.8em">{{ uc.employer_note }}</div>
                  </a-card>
              </div>
              <!-- <a-button style="margin-top:10px" size="small" >add</a-button> -->
          </section>
         <h4>Keywords</h4>
         <section style="padding-bottom:10px;">
           <a-tag v-for="(k,v) in subscriber.keywords">
             {{ k }}
           </a-tag>
           <!-- <a-button style="margin-top:10px" size="small" >add</a-button> -->
         </section>
         <section v-if="$auth.user.profile.role == 'admin'" style="padding-bottom:10px">
           <h4>Jira</h4>
            <div v-if="subscriber.info.jira">
              <a-button size="small" type="link" @click="openJiraTicket">{{ subscriber.info.jira.key }}</a-button>
              <a-tag>{{ subscriber.info.jira.status }}</a-tag>
            </div>
            <div v-else>
              <a-button size="small" type="default" @click="openJiraTicket">Open Ticket</a-button>
            </div>
        </section>
        <section>
         <h4>Comments</h4>
           <div>
             <a-list
               v-if="subscriber.comments.length"
                size="small"
               :data-source="subscriber.comments"
               item-layout="horizontal"
             >
               <a-list-item slot="renderItem" slot-scope="item, index">
                 <a-comment
                   :author="item.user.nickname"
                   :avatar="item.user.picture"
                   :content="item.content"
                   :datetime="item.datetime"
                 />
               </a-list-item>
             </a-list>
             <a-comment>
               <a-avatar
                 slot="avatar"
                 :src="$auth.user.picture"
                 alt="Han Solo"
               />
               <div slot="content">
                 <a-form-item>
                   <a-textarea :rows="4" :value="newComment.content" @change="handleChangeComment" />
                 </a-form-item>
                 <a-form-item>
                   <a-button size="small" html-type="submit" :loading="newComment.submitting" type="default" @click="handleSubmitComment">
                     Add Comment
                   </a-button>
                 </a-form-item>
               </div>
             </a-comment>
           </div>

         </section>

       </a-col>
     </a-row>
     <a-drawer
          :width="600"
          :visible="json"
          @close="json = false"
        >
        <div v-if="json" style="margin-top:20px;max-height:80vh;overflow-y:auto">
          <json-viewer
            :value="subscriber"
            :expand-depth="5"
          />
        </div>
      </a-drawer>

     <a-drawer
         :width="'60vw'"
         :visible="kysFirmPanel.show"
         @close="kysFirmPanel.show = false"
       >
       <div style="margin-top:20px;max-height:80vh;overflow-y:auto">
         <KysFirmPanel v-if="kysFirmPanel.show"
         :name="kysFirmPanel.name"
         :subscriber="subscriber"
         @update="updateSubscriberKysFirms"/>
       </div>
     </a-drawer>


  </div>
 </template>
<style>
.keyword_tag {
  display: inline-block;
  border: 1px solid black;
  padding: 3px;
}
</style>
 <script>

import KysFirmPanel from '@/components/kysfirm/KysFirmPanel.vue'
import KysFirmInfo from '@/components/kysfirm/KysFirmInfo.vue'
import LinkedIn from '@/components/Subscriber/LinkedIn.vue'
import RegisterResultCard from '@/components/RegisterResultCard.vue'
import EmployerResultCard from '@/components/EmployerResultCard.vue'

 export default {
   name: 'Subscriber',
   components: {
     LinkedIn,
     KysFirmPanel,
     KysFirmInfo,
     RegisterResultCard,
     EmployerResultCard
   },
   data: ()=>{
     return {
       usecaseDrawer: true,
       subscriberBookmarked: false,
       kysfirms: [],
       kysfirm_id_map: {},
       kysfirm_employer_map: {},
       json: false,
       newComment: {
         user: null,
         content: '',
         when_entered: new Date()
       },
       newCommentSubmitting: false,
       kysFirmPanel: {
         show: false,
         name: 'null',
         kysfirm: null
       },
       employerTable: {
         columns: [
           {
             title: 'Employer Names',
             dataIndex: 'names',
             key: 'names',
             scopedSlots: { customRender: 'names' },
           },
           {
             title: 'Source',
             dataIndex: 'source',
             key: 'source',
           },
           {
             title: 'Register',
             dataIndex: 'results.register',
             scopedSlots: { customRender: 'register' },
           },
           {
             title: 'S&P',
             dataIndex: 'results.sandp',
             scopedSlots: { customRender: 'sandp' },
           },
         ]
       },
     }
   },
   props: {
     subscriber: Object
   },
   watched: {
     'subscriber': function() {
       console.log('subscriber changed')
       if(this.subscriber.info.favorite){
         this.subscriberBookmarked  = true
       } else {
         this.subscriberBookmarked = false
       }
       this.updateSubscriberKysFirms()
       //this.searchFinra()
     }
   },
   computed: {
     defaultTab: function(){
       return this.confirmedRegisterResults.length > 0 ? 'Matched Results': 'All'
     },
     defaultRegisterTab: function(){
       console.log(this.confirmedRegisterResults, this.probableRegisterResults.length)
       return this.confirmedRegisterResults.length > 0 ? "1" :
        this.probableRegisterResults.length > 0 ? "2" : "1"
     },
     confirmedRegisterResults: function(){
       if( this.subscriber && this.subscriber.searches ) {
         return this.subscriber.searches.filter((s)=>{
           return s.view == 'confirm';
         })
       }
       return []
     },
     probableRegisterResults: function(){
       if( this.subscriber && this.subscriber.searches ) {
         return this.subscriber.searches.filter((s)=>{
           return s.view != 'confirm' && s.view != 'hidden';
         })
       }
       return []
     },
     employerMatches: function(){
       if( this.subscriber && this.subscriber.info && this.subscriber.info.employer_matches ) {
         return this.subscriber.info.employer_matches.sort(function(a, b) { return b.name_score - a.name_score })
       }
       return []
     },
     subscriberEmployerRegisterResults: function(){
       let allresults_ids = []
       let allresults = []
       if( this.subscriber && this.subscriber.info && this.subscriber.info.employer_register_results){
         this.subscriber.info.employer_register_results.forEach((result, i) => {
             if( !allresults_ids.includes(result.id || result.reference_id)){
               allresults_ids.push(result.id)
               allresults.push(result)
             }
         })
       }
       return allresults.sort((a,b) => b.name_score - a.name_score);
     },
     subscriberSANDPResults: function(){
       let sandp_map = {}
       if( this.subscriber && this.subscriber.info && this.subscriber.info.employer_sandp_results){
         for (const [key, results] of Object.entries(this.subscriber.info.employer_sandp_results)) {
             results.forEach((sandp_result, i) => {
               if( sandp_map.hasOwnProperty(sandp_result.id)){
                   sandp_map[sandp_result.id].score = Math.max(sandp_result.score, sandp_map[sandp_result.id].score)
               } else {
                 sandp_map[sandp_result.id] = sandp_result
               }
             });
         }
       }
       let sandp_array = Object.values(sandp_map)
       sandp_array.sort((a, b) => b.score - a.score);
       return sandp_array
     }
   },
   mounted: function() {
   },
   methods: {
     refreshSubscriber: async function(){
       let data = await this.$kysapi.get('api/subscriber/' + this.subscriber.id)
       this.subscriber = data.subscriber
       if( data.subscriber.info.employers){
         for( var i = 0 ; i < data.subscriber.info.employers.length ; i++){
           data.subscriber.info.employers[i].key = i;
         }
       }
     },
     configureKysFirm: function(firmName){
       console.log(firmName)
       this.kysFirmPanel.name = firmName
       this.kysFirmPanel.show = true
     },
     setRegisterResultView: async function(display, record) {
       let data = { subscriber_id: this.subscriber.id,
         search_result_id: record.id,
         action: 'update_search_result_display',
         field: 'view',
         field_value: display
       }

       record = display;
       await this.$kysapi.post('/api/subscriber/' + this.subscriber.id, data, this.$auth)
       console.log('updated')
       // this.$pnPublish(
       //     {
       //         channel: 'ch1',
       //         message: {
       //           subscriber: this.subscriber.id,
       //           event: 'update_register_record'
       //         }
       //     },
       //     (status, response) => console.log(status, response)
       // );
     },
     bookmarkSubscriber: async function(){
       // this.subscriber.info.favorite = !this.subscriber.info.favorite
       this.subscriberBookmarked = !this.subscriberBookmarked
       var response = await this.$kysapi.post('api/favorite/' + this.subscriber.id, {}, this.$auth)
     },
     searchFinra: async function(){

       let params = {
         name: this.subscriber.name,
         type: 'individual',
         state: this.subscriber.state
       }
       var basic_search = await this.$kysapi.post('api/brokercheck/', params, this.$auth)
       console.log('brokercheck', basic_search)
     },
     updateSubscriberKysFirms: async function(){

       const subscriber = this.drawer.subscriber

       var self = this

       self.drawer.kysfirms.length = 0
       self.drawer.kysfirm_id_map = {}
       self.drawer.kysfirm_employer_map = {}

       let kysfirm_map = self.drawer.kysfirm_id_map
       if( subscriber.info.employers){
         for( var i = 0 ; i < subscriber.info.employers.length ; i++){
           subscriber.info.employers[i].key = i;
           let key = subscriber.info.employers[i].name.toUpperCase()
           var kysfirm_response = await this.$kysapi.get('api/kysfirms?' + new URLSearchParams({name:key}).toString(), this.$auth)
           kysfirm_response.data.forEach((kysfirm, i) => {
             if(!kysfirm_map.hasOwnProperty(kysfirm.id)){
               kysfirm_map[kysfirm.id] = kysfirm

               self.drawer.kysfirms.push(kysfirm)

               if (self.drawer.kysfirm_employer_map.hasOwnProperty(key)) {
                 self.drawer.kysfirm_employer_map[key].push(kysfirm)
               } else {
                 self.drawer.kysfirm_employer_map[key] = []
                 self.drawer.kysfirm_employer_map[key].push(kysfirm)
               }
             }
           });
         }
         console.log('refreshed kysfirms', self.drawer.kysfirms)
       }
     },
     handleChangeComment(e){
       this.newComment.content = e.target.value
     },
     handleSubmitComment: async function() {
       this.newCommentSubmitting = true;
       this.newComment.user = this.$auth.user
       var response = await this.$kysapi.post('api/subscriber/' + this.subscriber.id + '/comments', this.newComment, this.$auth)
       setTimeout(() => {
         this.subscriber.comments = [ Object.assign({}, this.newComment), ...this.subscriber.comments]
         this.newComment.content = ''
         this.newCommentSubmitting = false
       }, 1000)
     },
     openJiraTicket: function() {

       if (this.subscriber.info.jira && this.subscriber.info.jira.key){
         window.open('https://prosparency.atlassian.net/browse/' + this.subscriber.info.jira.key, '_blank')

       } else {
          const q = new URLSearchParams({pid:10200,
          issuetype: 10006,
          summary :'Subscriber. ' + this.subscriber.id + " | " + this.subscriber.name,
          description: `Namelist: ${this.subscriber.namelist_name}\n\n`,
          customfield_10708: this.subscriber.id,
          reporter: this.$auth.user.email})
          window.open('https://prosparency.atlassian.net/secure/CreateIssueDetails!init.jspa?' + q.toString(), '_blank')

       }


     }
   }
 }
 </script>
