<template>
  <div>
      <a-layout>
        <a-layout-sider
           style="padding:10px;background:#fff;min-width:280px;width:250px;"
          >
          <h1>Usecases</h1>

          <div class="board-column">
            <h3 class="board-title">{{ unassigned.name }}</h3>
            <VueDraggable :list="unassigned.items" group="usecase" >
              <a-card
                v-for="(uc,i) in unassigned.items" :key="uc.name"
                size="small"
                animation="200"
                class="draggable"
                style="width:230px;margin:2px auto">
                  <div style="color:black;font-weight:500;font-size:12px;">{{ i+1 }}. {{ uc.name }}</div>
              </a-card>
             </VueDraggable>
             <a-button style="margin:auto;width:200px;" v-if="user.profile.role == 'admin'" @click="newUseCaseModal.show = true">New</a-button>
          </div>
        </a-layout-sider>
        <a-layout-content
          :style="{ padding: '24px 0px', background: '#fff', minHeight: '280px' }">
        <div>
          <h1><a-button type="primary" size="small" @click="newGroup">Group <a-icon type="plus"/></a-button></h1>
          <div class="board">

            <div class="board-column"
                  v-for="bucket in sorted_usecase_buckets">
              <h3 class="board-title">{{ bucket.name }} - {{ bucket.score }}
                <a-button size="small" style="float:right" icon="edit" @click="openUseCaseGroupModal(bucket)"/>
              </h3>
              <VueDraggable :list="bucket.items" group="usecase" @change="saveConfiguration">
                <a-card
                  v-for="(uc,i) in bucket.items" :key="uc.name"
                  size="small"
                  animation="200"
                  class="draggable"
                  style="width:230px;margin:2px auto">
                    <div style="color:black;font-weight:500;font-size:12px;">{{ i+1 }}. {{ uc.name }}</div>
                </a-card>
               </VueDraggable>
            </div>
            <div>
            </div>
          </div>
        </div>
      </a-layout-content>
    </a-layout>
    <a-modal
      title="New Useacase"
      :visible="newUseCaseModal.show"
      @ok="submitNewUseCase"
      @cancel="newUseCaseModal.show = false"
    >
    <a-form>
      <a-form-item label="Name">
        <a-input v-model="newUseCase.name" placeholder="Name" />
       </a-form-item>
       <a-form-item label="Description">
         <a-textarea v-model="newUseCase.description" placeholder="Description" :rows="4" />
       </a-form-item>
     </a-form>
    </a-modal>
    <a-modal
      title="Usecase Group"
      :visible="useCaseGroupModal.show"
      @ok="submitUseCaseGroup"
      @cancel="useCaseGroupModal.show = false"
    >
    <a-form>
      <a-form-item label="Name">
        <a-input v-model="useCaseGroup.name" placeholder="Name" />
       </a-form-item>
       <a-form-item label="Score">
        <a-input type="number" v-model="useCaseGroup.score" placeholder="Score" />
       </a-form-item>
       <a-form-item>
         <a-popconfirm  ok-text="Yes"
          cancel-text="No" @confirm="deleteGroup">
           <template slot="title">
             delete {{ useCaseGroup.name }} ?
           </template>
         <a-button type="danger">Delete</a-button>
         </a-popconfirm>
       </a-form-item>

     </a-form>
    </a-modal>
  </div>
</template>

<style scoped>

.ghost {
  opacity: 0.5;
  background: var(--v-primary-lighten1) !important;
}

.board {
  display: flex;
  align-items: start;
  overflow-x: scroll;
  height: calc(98vh - 8.6rem);
}

.board-column {
  flex: 0 0 27rem;
  display: flex;
  flex-direction: column;
  background-color: #f3f3f3;
  max-height: calc(100vh - 10.8rem);
  border-radius: 0.3rem;
  margin-right: 1rem;
  max-width:250px;
  padding-bottom: 15px;
}

.board-title {
  margin-left:15px;
  margin-right:10px;
  padding-top:10px;
  color:black;
}

.draggable:hover {
   cursor: -webkit-grab;
}

.draggable:active {
   cursor: -webkit-grabbing;
}


.v-application--is-rtl {
  .board-column-actions {
    left: 6px;
    right: auto;
  }
}
</style>
<script>

import VueDraggable from 'vuedraggable'

const kysapi = require('../kysapi')

export default {
  name: 'Usecases',
  components: {
    VueDraggable
  },
  data() {
    return {
      user: this.$auth.user,
      unassigned: {
        name: 'Unassigned',
        items: []
      },
      usecases: [],
      usecase_buckets: [],
      useCaseGroup: {
        name: null,
        score: 0,
        items: []
      },
      useCaseGroupModal: {
        show: false
      },
      newUseCase: {
        name: null,
        description: null
      },
      newUseCaseModal: {
        show: false
      }
    }
  },
  created(){
  },
  computed: {
    sorted_usecase_buckets: function() {
      return this.usecase_buckets.sort((a,b)=> parseInt(b.score) - parseInt(a.score))
    },
    dragOptions() {
      return {
        animation: 200,
        group: 'usecase',
        disabled: false,
        ghostClass: 'ghost'
      }
    }
  },
  mounted: async function(){


    var response = await this.$kysapi.get('api/usecases', this.$auth)


    console.log('usecases', response.data)

    this.usecases = response.data

    let url = 'api/usecases/configuration/'
    if (this.$route.params.client) {
        url += this.$route.params.client
    }
    url += '/default'

    const configuration_response = await this.$kysapi.get(url, this.$auth)

    console.log('configuration', configuration_response.data.configuration)

    const group_map = {}
    const assigned_map = {}

    configuration_response.data.configuration.groups.forEach((group, i) => {
      group.items = []
      this.usecase_buckets.push(group)
      group_map[group.name] = group
    });

    configuration_response.data.configuration.usecases.forEach((uc, i) => {
      assigned_map[uc.name] = uc.group
    });

    for(let i = 0 ; i < this.usecases.length ; i ++) {
      let uc = this.usecases[i]
      if (assigned_map.hasOwnProperty(uc.name)) {
        group_map[assigned_map[uc.name]].items.push(uc)
      } else {
        this.unassigned.items.push(uc)
      }
    }

  },
  methods: {
    submitNewUseCase: async function() {
      console.log(this.newUseCase)
      const response = await this.$kysapi.post('api/usecases', this.newUseCase, this.$auth)

      console.log(response)
      alert('usecase added')
    },
    openUseCaseGroupModal: function(group) {
      this.useCaseGroup = group
      this.useCaseGroupModal.show = true
    },
    submitUseCaseGroup: function () {
      console.log(this.useCaseGroup)

      const matches = this.usecase_buckets.filter((group)=> group.name == this.useCaseGroup.name)
      if(matches.length === 0){
        this.usecase_buckets.push(Object.assign({}, this.useCaseGroup))
      }
      this.saveConfiguration()
      this.useCaseGroupModal.show = false
    },
    newGroup: function() {
      this.useCaseGroup = {
        name: '',
        score: 0,
        items: []
      }
      this.useCaseGroupModal.show = true
    },
    deleteGroup: function() {
      let index = -1


      for(let i = 0; i < this.usecase_buckets.length; i++) {
        if( this.usecase_buckets[i].name == this.useCaseGroup.name) {
          if (this.usecase_buckets[i].items.length > 0) {
            alert('can not delete group with items assigned')
            return
          }
          index = i
        }
      }
      if (index != -1) {
        this.usecase_buckets.splice(index, 1)
      }
      this.useCaseGroupModal.show = false

    },
    saveConfiguration: async function() {
      console.log(this.usecase_buckets)

      const configuration = {
        groups: [],
        usecases: []
      }

      this.usecase_buckets.forEach((bucket)=>{
        if (bucket.name != 'Unassigned') {
          configuration.groups.push({
            name:bucket.name,
            score: bucket.score,
          })

          bucket.items.forEach((item, i) => {
            configuration.usecases.push({
              name: item.name,
              group: bucket.name
            })
          });
        }
      })
      console.log(configuration)

      let response = await this.$kysapi.post('api/usecases/configuration/default', configuration, this.$auth)
    },
    getBucket: function(name) {
      let matches = this.usecase_buckets.filter((item) => item.name == name)
      if (matches.length == 0){
        let bucket = {
          name: name,
          score: 0,
          items: []
        }
        this.usecase_buckets.push(bucket)
        return bucket
      } else {
        return matches[0]
      }
    }
  }
};
</script>
<style>
#components-layout-demo-custom-trigger .trigger {
  font-size: 18px;
  line-height: 64px;
  padding: 0 24px;
  cursor: pointer;
  transition: color 0.3s;
}

#components-layout-demo-custom-trigger .trigger:hover {
  color: #1890ff;
}

#components-layout-demo-custom-trigger .logo {
  height: 32px;
  background: rgba(255, 255, 255, 0.2);
  margin: 16px;
}
</style>
