<template>
  <div class="">
      <a-layout>
        <a-layout-sider v-model="collapsed" :trigger="null" collapsible
          style="padding-top:20px;margin-left:10px;width:200px;background:#fff"
        >
        <kys-sidebar></kys-sidebar>
        </a-layout-sider>
        <a-layout>
          <a-layout-content
            :style="{ padding: '24px', background: '#fff', minHeight: '280px' }"
          >
          <a-breadcrumb>
             <a-breadcrumb-item v-if="$auth && $auth.user">
               <router-link :to="{name: 'Home'}"><a-icon type="home" /></router-link>
             </a-breadcrumb-item>
             <a-breadcrumb-item>{{ namelist_name }} </a-breadcrumb-item>
          </a-breadcrumb>
          <div v-if="selected">

          <h1 class="kys-h1">Summary</h1>
            <a-card class="kys-card">
                <a-row>
                  <a-col span="10" style="min-width:500px;">
                   <apexchart
                     type="donut"
                     width="450"
                     :options="usecase_group.options"
                     :series="usecase_group.series"
                   ></apexchart>
                 </a-col>
                 <a-col span="3">
                   <a-statistic title="Subscribers" :value="selected.num_subscribers" style="margin-right: 50px" />
                   <router-link :to="{path: '/n/' + selected.name + '/subscribers' , query: {}}">View Details</router-link>
                 </a-col>
               </a-row>
            </a-card>
            <kys-file-viewer :files="selected.files"></kys-file-viewer>
            <h1 class="kys-h1">Usecase Results</h1>
            <a-card class="kys-card">
              <a-row type="flex" :gutter="16">
                <a-col>
                  <a-table :columns="resultStatColumns" 
                           :rowKey="'name'" 
                           :data-source="selected.result_stats"
                           :pagination="false">
                   <router-link slot="name" :to="{path: '/n/' +  selected.name + '/subscribers', query: {result: name }}" slot-scope="name">{{ name }}</router-link>
                  </a-table>
                </a-col>
                <a-col>
                  <a-table :columns="usecaseStatColumns" 
                           :rowKey="'name'" 
                           :data-source="selected.usecase_stats"
                           :pagination="false">
                   <router-link slot="name" :to="{path: '/n/' +  selected.name + '/subscribers' , query: {usecase: name }}" slot-scope="name">{{ name }}</router-link>
                  </a-table>
                </a-col>
              </a-row>
            </a-card>

            <h1 class="kys-h1">Statistics</h1>
            <a-card  class="kys-card">
              <a-row type="flex" :gutter="16">
                <a-col :span="12">
                  <a-table :columns="countryStatColumns" 
                           :rowKey="'keyword'" 
                           :data-source="selected.stats.country">
                    <router-link slot="keyword" :to="{path: '/n/' +  selected.name + '/subscribers', query: {country: name }}" slot-scope="name">{{ name }}</router-link>
                  </a-table>
                </a-col>
              </a-row>
            </a-card>
            
          </div>
          </a-layout-content>
        </a-layout>
      </a-layout>
  </div>
</template>
<style>

  .kys-h1 {
    padding-top: 20px;
  }  

  .kys-card {
    max-width: 1080px;
  }
</style>
<script>
import KysFileViewer from "../components/FileViewer";
import KysSidebar from "../components/HomeSidebar";

const fb = require('../firebaseConfig.js')
const kysapi = require('../kysapi')

export default {
  name: 'NamelistSummary',
  components: {
    KysFileViewer,
    KysSidebar
  },
  data() {
    return {
      namelist_name: "",
      namelists: [],
      selected: null,
      user: fb.session.user,
      usecase_group: {
        series: [],
        options: {
          labels: [],
          chart: {
            type: 'donut'
          },
          responsive: [{
            breakpoint: 480,
            options: {
              chart: {
                width: 50
              },
              legend: {
                position: 'bottom'
              }
            }
          }]
        }
      },
      namelistTableColumns: [
        {
          dataIndex: 'when_entered_date',
          key: 'when_entered_date',
          title:'Submitted'
        },
        {
          dataIndex: 'name',
          key: 'name',
          title:'Name'
        },
        {
          title: 'Subscribers',
          scopedSlots: { customRender: 'subscribers' },
          key: 'num_subscribers',
        },
        {
          title: 'Reports',
          scopedSlots: { customRender: 'reports' },
          key: 'process_stats',
        },
      ],
      countryStatColumns: [
        {
          dataIndex: 'keyword',
          key: 'keyword',
          scopedSlots: { customRender: 'keyword' },
          title:'Country'
        },
        {
          title: 'count',
          dataIndex: 'count',
          key: 'count',
        },
      ],
      resultStatColumns: [
        {
          dataIndex: 'score',
          key: 'score',
          title:'Score'
        },
        {
          dataIndex: 'name',
          key: 'name',
          scopedSlots: { customRender: 'name' },
          title:'Result'
        },
        {
          title: 'count',
          dataIndex: 'count',
          key: 'count',
        },
      ],
      usecaseStatColumns: [
        {
          dataIndex: 'name',
          key: 'name',
          scopedSlots: { customRender: 'name' },
          title:'Usecase'
        },
        {
          title: 'count',
          dataIndex: 'count',
          key: 'count',
        },
      ],
      collapsed: false
    }
  },
  mounted: async function () {
    this.namelist_name = this.$route.params.id
    this.getNamelist(this.$route.params.id)
  },
  methods: {
    getNamelist: async function (namelist) {

      var response = await this.$kysapi.get('/api/namelists/' + this.$route.params.id , this.$auth)
      console.log(this.$route)
      console.log(response)
      this.namelist = response.data.namelist

      var stats_response = await this.$kysapi.get('api/namelists/' + this.namelist.id + '/stats', this.$auth)

      this.namelist = Object.assign(this.namelist , stats_response.data);

      console.log(this.namelist)

      this.selected = this.namelist

      this.usecase_group.options.labels = []
      this.usecase_group.series = []

      const configuration_response = await this.$kysapi.get('api/usecases/configuration/default', this.$auth)
      console.log('configuration', configuration_response.data.configuration)

      const pieData = {}

      const group_map = {}
      const usecase_map = {}


      const self = this
      configuration_response.data.configuration.groups.forEach((group, i) => {
        pieData[group.name] = 0
      });

      configuration_response.data.configuration.usecases.forEach((uc, i) => {
        usecase_map[uc.name] = uc.group
      });


      this.selected.usecase_stats.forEach((uc, i) => {    
        
        let result_name = usecase_map[uc.name]

        if(self.usecase_group.options.labels.includes(result_name)){
        }
        
        pieData[result_name] += parseInt(uc.count)

      });

      self.selected.result_stats = []

      configuration_response.data.configuration.groups.forEach((group, i) => {

        group.count = pieData[group.name]
        if(group.count > 0){
          self.usecase_group.series.push(pieData[group.name])
          self.usecase_group.options.labels.push(group.name)
        }
        self.selected.result_stats.push(group)
      });
    },
    download_file: async function(file){
      console.log('api/file/' + file.key)
      const response = await this.$kysapi.get('api/file/' + file.key, this.$auth)
      console.log(response)
      const blob = new Blob([response.data], { type: "text/csv" });
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = file.name;
      link.click();
      URL.revokeObjectURL(link.href);
    },
  }
}
</script>
