<template>
  <div class="">
      <a-layout>
        <a-layout-sider v-model="collapsed" :trigger="null" collapsible
          style="padding-top:20px;margin-left:10px;width:200px;background:#fff"
        >
          <kys-sidebar ></kys-sidebar>
        </a-layout-sider>
        <a-layout>
          <a-layout-content
            :style="{ padding: '24px', background: '#fff', minHeight: '280px' }"
          >
          as of {{  last_check  }}
          <a-card class="kys-card" :bodyStyle="{}" 
                      v-for="queue in queues" 
                      :key="queue.q"   
              >
                <h1>{{ queue.q }}</h1>
                <a-row type="flex" :gutter="10">

                  <a-col>
                      <a-statistic title="Entries" :value-style="{ 'font-size': '1.2em', color: 'blue' }" :value="queue.depth" style="margin-right: 50px" />
                    </a-col>
                  </a-row>
              </a-card>  

          
          </a-layout-content>
        </a-layout>
      </a-layout>
  </div>
</template>
<style>

  .kys-h1 {
    padding-top: 20px;
  }  

  .kys-card {
    margin-bottom: 15px;
    max-width: 1080px;
  }

  .kys-card .ant-card-body {
    padding: 10px 20px;
  }

</style>
<script>

import KysSidebar from "../../components/HomeSidebar";
import moment from 'moment'

export default {
  name: 'InternalLinkedin',
  components: {
    KysSidebar
  },
  data() {
    return {
      collapsed: false,
      last_check: new Date(),
      queues: [],
      is_active: false
    }
  },
  mounted: async function () {
    this.getQueueDepth()
    this.interval = setInterval( function () {
      // if(this.queue.count === 0 && new Date().getTime() - this.last_check < 5 * 50000 ){
      //   console.log('sleeping additional time')
      //   return
      // }
      console.log(new Date())
      this.last_check = new Date()
      this.getQueueDepth()
    }.bind(this), 5000); 

  },
  computed: {

  },
  methods: {
    getPercent(s){
      let pct = 100 - (s.stale + s.new) / ( s.stale + s.new + s.linkedin_found + s.no_match ) * 100
      return parseInt(pct)
    },
    fromNow (t) {
      return moment(t).fromNow()
    },
    getStatusColor (s) {
      return s == 'ERROR' ? '#f50' : s == 'LINKEDIN_FOUND' ? 'blue' : ''
    },
    getQueueDepth: async function () {
      var response = await this.$kysapi.get('api/workflow/queues' , this.$auth)
      console.log(response)
      this.queues = response.data.queues
    },
  },
  beforeDestroy: function(){
    clearInterval(this.interval);
  }
}
</script>
